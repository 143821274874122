import $ from "jquery";

$(function() {
    $(".bl-joblist").each(function() {
        const $bl = $(this);
        initJobList($bl);
    });
});

function initJobList($bl) {
    //desktop
    $bl.on("mouseenter", ".item", function() {
        const $button = $(this).find(".button");
        $button.addClass("hover");
    });
    $bl.on("mouseleave", ".item", function() {
        const $button = $(this).find(".button");
        $button.removeClass("hover");
    });
}

