import $ from "jquery";

import Swiper from 'swiper/bundle';

$(function() {
    $(".bl-carouselslider").each(function() {
        const $bl = $(this);
        initCarouselSlider($bl);
    });
});

function initCarouselSlider($bl) {
    //desktop
    $bl.on("mouseenter", ".item", function() {
        const $button = $(this).find(".button");
        $button.addClass("hover");
    });
    $bl.on("mouseleave", ".item", function() {
        const $button = $(this).find(".button");
        $button.removeClass("hover");
    });
    $bl.on("click", ".item", function() {
        const $button = $(this).find(".button");
        const href = $button.attr("href");
        window.location.href = href;
    });

    //mobile
    const swiper = new Swiper($bl.find(".swiper").get(0), {
      loop: true,
      centeredSlides: true,
      slidesPerView: "auto",
      //rewind: true,
      //autoHeight: true,
    });
}

