import $ from "jquery";
import Isotope from "isotope-layout"; 
import 'isotope-packery';
// npm install isotope-layout
// npm install isotope-packery

$(function() {
    $(".bl-magazinlist").each(function() {
        const $bl = $(this);

        //Init isotope
        const $guttersizer = $bl.find(".guttersizer");
        const $columnsizer = $bl.find(".columnsizer");

        const iso = new Isotope(".bl-magazinlist .ctritems", {
            layoutMode: 'packery',
            itemSelector: ".box",
            percentPosition: true,
            packery: {
                gutter: $guttersizer.get(0),
                columnWidth: $columnsizer.get(0)
            }
        });

        $(window).on("load", function() {
            iso.layout();
        });


        //Init filter
        const $filterbuttons = $bl.find(".ctrfilterbuttons");
        $filterbuttons.on("click", ".filterbutton", function() {
            const $filterbutton = $(this);
            const requestedFilter = $filterbutton.data("filter");

            highlightFilterButton(requestedFilter);
            filterIsotope(requestedFilter);
        });

        function highlightFilterButton(requestedFilter) {
            $filterbuttons.find(".filterbutton").removeClass("active");
            $filterbuttons.find("[data-filter='"+requestedFilter+"']").addClass("active");
        }

        function filterIsotope(requestedFilter) {
            if(requestedFilter == "*") {
                clearFilterIsotope();
                return;
            }

            iso.arrange({
                filter: ".filter-"+requestedFilter
            });
        }

        function clearFilterIsotope() {
            iso.arrange({
                filter: ""
            });
        }
    
        //window.iso = iso;
        window.filterIsotope = function() {
            iso.arrange({
                filter: ".filter-idx2"
            });
        }
        window.clearFilterIsotope = function() {
            iso.arrange({
                filter: ""
            });
        }
    });
});
