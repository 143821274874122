import $ from "jquery"

$(function() {
     const $menu = $("#overlaymenu");
    const $menubutton = $("#menubutton");
    const $body = $("body");

    //toggle on/off
    $menubutton.on("click", function() {
        toggleOverlayMenu();
    });

    function toggleOverlayMenu() {
        const overlayMenuVisible = $menu.hasClass("visible");
        if(overlayMenuVisible) {
            hideOverlayMenu();
        } else {
            showOverlayMenu();
        }
    }

    function showOverlayMenu() {
        $menu.addClass("visible");
        $menubutton.addClass("is-active");
        $body.addClass("overlaymenuvisible");
    }

    function hideOverlayMenu() {
        $menu.removeClass("visible");
        $menubutton.removeClass("is-active");
        $body.removeClass("overlaymenuvisible");
    }

    //mobile: enhance html
    function enhanceMobileHtml() {
        $menu.find(".menuitems-mobile li.hassubmenu > a").append('<svg class="icon icon-arrowdown"><use xlink:href="#icon-arrowdown"></use></svg>');
    }
    enhanceMobileHtml();

    //desktop: fill second column
    function fillDesktopSecondColumn() {
        const $micol2ul = $('<ul class="menu"></ul>');

        $menu.find(".mi68").nextAll().addBack().appendTo($micol2ul);
    
        const $micol2 = $menu.find(".micol2");
        $micol2ul.appendTo($micol2);
    }
    fillDesktopSecondColumn();
});
