import $ from "jquery";
//import "what-input";

window.$ = $;
window.jQuery = $;


//Libraries without npm support
//https://github.com/dimsemenov/photoswipe-video-plugin
import "./libraries/photoswipe-video-plugin.esm.js";

//Pixmill Scripts
import { ready } from "./px/utils.js";

//import "./px/hamburger.js";
//import "./px/header.js";
//import "./px/form.js";
import "./px/reveal_animationapi.js";
//import "./px/customreveal.js";
import "./px/swiper.js";

//import "./px/dropdownmenu.js"; //part of library
//import "./px/pagedmenu.js"; //part of library
import "./px/accordionmenu.js"; //part of library
//import "./px/desktopmenu.js";
import "./px/overlaymenu.js";

//import "./px/vorgehen.js";
import "./px/accordion.js";
//import "./px/photoswipe.js";
import "./px/scrolltohash.js";
//import "./px/medialightbox.js";
//import "./px/totopbutton.js";

/*
import "./px/hero.js";
import "./px/reflist.js";
import "./px/joblist.js"; //isotope
import "./px/bloglist.js"; //isotope
import "./px/textwithcircleanim.js";
import "./px/introanimation.js";
*/
import "./px/carouselslider.js";
import "./px/hgallery.js";
import "./px/magazinlist.js"; //isotope
import "./px/joblist.js";



/*
import { addFunctionalityToProgressPath } from "./px/scrollProgress.js";

ready(function () {
  addFunctionalityToProgressPath("progress-wrap");
});
*/

